.preLoader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.952);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preLoader .preLoaderCircle {
  width: 50px;
  height: 50px;
  border-radius: 15%;
  background-color: #fff;
  animation: preLoaderCircle 1.5s infinite linear;
}

@keyframes preLoaderCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


