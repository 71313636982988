@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Ubuntu:wght@300;400;500;700&display=swap);
* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

html {
  padding-top: 0;
}

section {
  margin: 10vh o;
}

.container {
  max-width: 1000px;
  padding: 0 1rem;
  margin: 0 auto;
}


.dot::before {
  content: '.';
  display: inline-block;
  color: #FF7E67;
}


/* Animations */
@keyframes fade-right {

}

/* width */
::-webkit-scrollbar {
  width: 10px;
}


/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5121b1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5D22D3;
  width: 20px;
}


.Navbar_navbar__2jUwO {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 77 !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1rem 4rem;
  background-color: #5D22D3;
  transition: 300ms ease-in-out;
}

.page-ready .Navbar_navbar__2jUwO {
  animation: Navbar_navbar-anim__2C86O 0.5s forwards   
}

.Navbar_navbar__2jUwO.down {
  top:-10rem;
} 



@keyframes Navbar_navbar-anim__2C86O{
  from {
    transform: translateY(-300px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.Navbar_logo__1LbVN{
  display: inherit;
  width: 200px;
}

.Navbar_icon__3wFE4 {
  display: none;
}
.Navbar_navbarMenu__3pN3A > * {
  margin: 0 1rem;
  color: white;
  font-size: 1.6rem;
  font-weight: 400;
  text-decoration: none;
}


.Navbar_navbarLink__233-R {
  position: relative;
  display: inline-block;
  transition: 300ms ease-in-out;
  cursor: pointer;
  font-weight: 400;
  height: 100%;
  justify-content: center;
}

.Navbar_navbarLink__233-R.active {
  font-weight: 900;
}

.Navbar_navbarLink__233-R:hover {
  letter-spacing: 4px;
}  

.Navbar_navbarLink__233-R > span {
  content: "";
  position: absolute;
  bottom:.2rem;
  left: 0;
  transition: 300ms;
  height: .4rem;
  background-color: #FF7E67;
  width:15%;
  opacity: 0;
}

.Navbar_navbarLink__233-R:hover > span {
 opacity: 1;
}




@media  (max-width: 1000px) {
  .Navbar_navbar__2jUwO {
    display: block;
    padding: 1rem 1.4rem;
  }

  .Navbar_logo__1LbVN {
    width: 30%;
  }

  .Navbar_icon__3wFE4 {
    display: block;
  }

  .Navbar_brand__3D6oL {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  
  .Navbar_navbarMenu__3pN3A {
    display: block;
    width: 100%;
    text-align: center;
    transition: 300ms;
    overflow: hidden;
    transform-origin: top center;
    height: 0;
    padding: 0;
  }

  .Navbar_navbarMenu__3pN3A.active {
    height: auto;
    padding: 1.4rem 0;
  }

  .Navbar_navbarMenu__3pN3A > * {
    margin: .4rem;
    display: block;
  }
}
.Header_wrapper__2GulC {
  width: 100%;
  min-height: 100vh;
}

.Header_container__2fFZJ {
  padding-top: 25vh;
  position: relative;
  z-index: 15 !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.Header_banner__1qxxN {
  font-family: 'Roboto', sans-serif;
  color: white;
  font-size: 8rem;
  line-height: 85%;
  font-weight: 700;
  transform: translateX(-100%);
  opacity: 0;
}

.Header_banner__1qxxN .Header_subtitle__2d42R {
  font-size: 27%;
  line-height: 150%;
  letter-spacing: 0.4rem;
  font-weight: 400;
  opacity: 0.7;
  letter-spacing: 0.4rem;
}

.Header_ppWrapper__KwrNr {
  width: 25%;
  display: block;
  max-width: 600px;
  transform: translateX(100%);
  opacity: 0;
}


.Header_pp__172gc {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.199);
}

.Header_pp__172gc .Header_pp-title__1jN68 {  
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8px;
  background-color: #555;
}

.Header_bg__37MOi {
  width: 100%;
  height: 100% !important;
  z-index: 0 !important;
  height: auto;
  position: absolute;
  transition: 300ms;
  background-color: #5d22d3;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
          clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
  top: 0;
  left: 0;
}


.page-ready .Header_bg__37MOi {
  animation: Header_bg-anim__3jR3C;
  animation-delay: 0.2s;
  animation-duration: 350ms;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.page-ready .Header_ppWrapper__KwrNr {
  animation: Header_pp-anim__1d-Zj;
  animation-delay: 0.7s;
  animation-duration: 500ms;
  animation-fill-mode: forwards;
}

.page-ready .Header_banner__1qxxN {
  animation: Header_banner-anim__3zsgj;
  animation-delay: 0.7s;
  animation-duration: 350ms;
  animation-fill-mode: forwards;
}

@keyframes Header_bg-anim__3jR3C {
  0% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
            clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
  }
  100% {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 45%, 0 65%);
            clip-path: polygon(0 0, 100% 0, 100% 45%, 0 65%);
  }
}

@keyframes Header_banner-anim__3zsgj {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes Header_pp-anim__1d-Zj {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 1000px) {
  .Header_container__2fFZJ {
    padding: 0 1.4rem;
    padding-top: 15vh;
    display: block;
  }

  .Header_ppWrapper__KwrNr {
    margin-top: 3rem;
    width: 80%;
    margin-left: 10%;
    text-align: center;
  }


  .Header_banner__1qxxN {
    font-size: 4rem;
    letter-spacing: 105%;
  }

  @keyframes Header_bg-anim__3jR3C {
    0% {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
              clip-path: polygon(0 0, 100% 0, 100% 0%, 0 0%);
    }
    100% {
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 60%, 0 70%);
              clip-path: polygon(0 0, 100% 0, 100% 60%, 0 70%);
    }
  }
}

.SectionTitle_title__1kMNE {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 4rem;

  height: 8rem;
  

  text-align: center;
  width: 100%;


  color: #5d22d3;
}

.SectionTitle_title__1kMNE.invert {
  color: white;
}

.SectionTitle_title__1kMNE.small {
  font-size: 3rem !important;
  height: 5rem;
  margin: 0;
  text-align: left;
}


@media (max-width: 1000px) {
  .SectionTitle_title__1kMNE {
    font-size: 2.3rem;
  }
}

.About_content__35b09 {
  font-size: 1.7rem;

}


@media (max-width: 1000px) {
  .About_content__35b09 {
    font-size: 1.3rem;
  }
}

.PreLoader_preLoader__1yZoX {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.952);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PreLoader_preLoader__1yZoX .PreLoader_preLoaderCircle__269ga {
  width: 50px;
  height: 50px;
  border-radius: 15%;
  background-color: #fff;
  animation: PreLoader_preLoaderCircle__269ga 1.5s infinite linear;
}

@keyframes PreLoader_preLoaderCircle__269ga {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}



.MyProjects_container__37hHy {
  min-height: 100vh;
}

.ProjectTile_tile__2FzA2 {
  display: grid;
  grid-template-columns: auto 25%;
  transition: 300ms;
  padding: 2rem;
  margin: 5rem 0;
  border-radius: 15px;
  cursor: pointer;
  text-decoration: none;
}


.ProjectTile_tile__2FzA2:hover {
  box-shadow: 6px 6px 14px 0 rgb(0 0 0 / 20%),
    -8px -8px 18px 0 hsl(0deg 0% 100% / 55%);
}

.ProjectTile_info__2T7MW {
  font-size: 1.7rem;
}

.ProjectTile_title__3Rqj3 {
  font-size: 6rem;
  font-weight: 700;
  color: #5d22d3;
}

.ProjectTile_description__iuT9G {
  margin-top: 2rem;
  margin-bottom: 4rem;
  color: rgb(15, 14, 14);
  font-weight: 300;
}

.ProjectTile_img__1U8BP {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ProjectTile_img__1U8BP img {
  width: 100%;
  height: auto;
}

.ProjectTile_tags__3lZ9A {
  display: inline-flex;
  flex-wrap: wrap;
}

.ProjectTile_tag__Yk62_ {
  padding: 0.4rem 1rem;
  color: white;
  background-color: #ff7e67;
  margin-right: 1rem;
  box-shadow: 1px 2px 5px 1px #8b534980;
}

@media (max-width: 1000px) {
  .ProjectTile_tile__2FzA2 {
    display: flex;
    flex-direction: column-reverse;
  }

  .ProjectTile_title__3Rqj3 {
    font-size: 3rem;
  }

  .ProjectTile_description__iuT9G {
    margin-top: 2rem;
    margin-bottom: 2rem;
    font-weight: 300;
    font-size: 1.3rem;
  }
  

  .ProjectTile_img__1U8BP {
    width: 60%;
    margin-left: 20%;
    text-align: center;
  }
}

.Contact_container__1n1Rq {
  display: grid;
  width: 100%;
  max-width: 1200px !important;
  padding: 1rem;
  padding-bottom: 3rem;
  grid-gap: 10rem !important;
  gap: 10rem !important;
  grid-template-columns: 40% auto;
}

.Contact_wrapper__2nTTd {
  background-color: #5d22d3;
  padding-top: 10vh;
  margin-top: 15vh;
  -webkit-clip-path: polygon(0 17%, 100% 0, 100% 100%, 0% 100%);
          clip-path: polygon(0 17%, 100% 0, 100% 100%, 0% 100%);
  color: white;
}

.Contact_left__372Nl .content {
  display: flex;
  flex-direction: column;
}

.Contact_left__372Nl a {
  text-decoration: none;
  color: white;
  transition: 0.3s;
}

.Contact_left__372Nl a:hover {
  text-decoration: underline;
}

.Contact_left__372Nl .content-header {
  font-size: 150%;
  opacity: 0.7;
  line-height: 0.1rem;
}

.Contact_icon__2TCRb {
  display: inline-block;
  width: 2rem;
  margin-right: 1rem;
}

.Contact_icon__2TCRb img {
  width: 100%;
}

.Contact_icon__2TCRb img {
  color: white;
}

.Contact_right__3c-9u {
  margin-top: 5rem;
  display: flex;
  background-color: white;
  border-radius: 12px;
  grid-gap: 1rem;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  box-shadow: 1px 2px 5px 1px #8b534980;
  transition: 0.3s;
}

.Contact_right__3c-9u.submitting {
  transform: scale(0.1);
  animation: Contact_rotation-anim__1W_fs 500ms infinite;
  animation-delay: 300ms;
}

@keyframes Contact_rotation-anim__1W_fs {
  from {
    transform: scale(.1) rotate(0deg);
  }
  to {
    transform: scale(.1) rotate(360deg);
  }
}

.Contact_right__3c-9u * {
  transition: 0.3s;
}

.Contact_right__3c-9u.submitting * {
  opacity: 0;
}

.Contact_right__3c-9u h2 {
  color: #5d22d3;
}
.Contact_right__3c-9u h2::after {
  content: '.';
  color: red;
  display: inline-block;
  transform: translateX(-0.4rem);
}

.Contact_right__3c-9u button {
  border: none;
  padding: 0.4rem 1rem;
  background-color: #ff7e67;
  color: white;
  font-size: 1.4rem;
  box-shadow: 1px 1px solid rgba(128, 128, 128, 0.349);
  cursor: pointer;
  transition: 300ms;
}
.Contact_right__3c-9u button:enabled:hover {
  transform: scale(1.1) rotate(-8deg);
}

.Contact_right__3c-9u button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.Contact_inputBox___0e5r {
  position: relative;
  width: 100%;
}

.Contact_inputBox___0e5r input,
.Contact_inputBox___0e5r textarea {
  width: 100%;
  padding: 5px 0;
  font-size: 1rem;
  margin: 10px 0;
  border: none;
  border-bottom: 1px solid #5d22d3;
  outline: none;
  resize: none;
}
.Contact_inputBox___0e5r span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

.Contact_inputBox___0e5r input:focus ~ span,
.Contact_inputBox___0e5r input:valid ~ span,
.Contact_inputBox___0e5r textarea:focus ~ span,
.Contact_inputBox___0e5r textarea:valid ~ span {
  color: #263849;
  font-size: 12px;
  transform: translateY(-20px);
}


.Contact_message__2Zo10 {
  text-align: center;
  color: #5d22d3;
  font-weight: 600;
  font-size: 1.7rem;
}

.Contact_message__2Zo10 img {
  width: 30%;
  height: auto;
}


@media (max-width: 1000px) {
  .Contact_container__1n1Rq {
    display: flex;
    flex-direction: column;

  }
  .Contact_wrapper__2nTTd {
    -webkit-clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 5%, 100% 0, 100% 100%, 0% 100%);
  }
} 
.Footer_container__2mekV {
  padding: 1rem;
  text-align: center;
  background-color: #2b125c;
  color: white;
  margin-top: -5px;
}
