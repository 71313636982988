@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&family=Ubuntu:wght@300;400;500;700&display=swap");

* {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;
}

html {
  padding-top: 0;
}

section {
  margin: 10vh o;
}

.container {
  max-width: 1000px;
  padding: 0 1rem;
  margin: 0 auto;
}


.dot::before {
  content: '.';
  display: inline-block;
  color: #FF7E67;
}


/* Animations */
@keyframes fade-right {

}

/* width */
::-webkit-scrollbar {
  width: 10px;
}


/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5121b1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #5D22D3;
  width: 20px;
}

