.content {
  font-size: 1.7rem;

}


@media (max-width: 1000px) {
  .content {
    font-size: 1.3rem;
  }
}
