.navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 77 !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 1rem 4rem;
  background-color: #5D22D3;
  transition: 300ms ease-in-out;
}

:global(.page-ready) .navbar {
  animation: navbar-anim 0.5s forwards   
}

.navbar:global(.down) {
  top:-10rem;
} 



@keyframes navbar-anim{
  from {
    transform: translateY(-300px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.logo{
  display: inherit;
  width: 200px;
}

.icon {
  display: none;
}
.navbarMenu > * {
  margin: 0 1rem;
  color: white;
  font-size: 1.6rem;
  font-weight: 400;
  text-decoration: none;
}


.navbarLink {
  position: relative;
  display: inline-block;
  transition: 300ms ease-in-out;
  cursor: pointer;
  font-weight: 400;
  height: 100%;
  justify-content: center;
}

.navbarLink:global(.active) {
  font-weight: 900;
}

.navbarLink:hover {
  letter-spacing: 4px;
}  

.navbarLink > span {
  content: "";
  position: absolute;
  bottom:.2rem;
  left: 0;
  transition: 300ms;
  height: .4rem;
  background-color: #FF7E67;
  width:15%;
  opacity: 0;
}

.navbarLink:hover > span {
 opacity: 1;
}




@media  (max-width: 1000px) {
  .navbar {
    display: block;
    padding: 1rem 1.4rem;
  }

  .logo {
    width: 30%;
  }

  .icon {
    display: block;
  }

  .brand {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbarMenu {
    display: block;
    width: 100%;
    text-align: center;
    transition: 300ms;
    overflow: hidden;
    transform-origin: top center;
    height: 0;
    padding: 0;
  }

  .navbarMenu:global(.active) {
    height: auto;
    padding: 1.4rem 0;
  }

  .navbarMenu > * {
    margin: .4rem;
    display: block;
  }
}